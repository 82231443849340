function getLocalStorage(): Storage {
  if (!window.localStorage) {
    throw new Error('LocalStorage not available');
  }

  return window.localStorage;
}

export function getFromLocalStorage(key: string): string | null {
  try {
    return getLocalStorage().getItem(key);
  } catch (e) {
    console.warn('LocalStorage failed', e);

    return null;
  }
}

export function setInLocalStorage(key: string, value: string): void {
  try {
    getLocalStorage().setItem(key, value);
  } catch (e) {
    console.warn('LocalStorage failed', e);
  }
}

export function getIntegerFromLocalStorage(key: string): number | null {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let val: any = getFromLocalStorage(key);
  if (val) {
    val = Number(val);
    if (Number.isInteger(val)) {
      return val;
    }
  }

  return null;
}

export function setIntegerInLocalStorage(key: string, value: number): void {
  setInLocalStorage(key, String(value));
}

export function getObjectFromLocalStorage<T = Record<string,  unknown>>(key: string): T | null {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const val: any = getFromLocalStorage(key);
  if (!val) {
    return null;
  }

  return JSON.parse(val);
}

export function setObjectInLocalStorage<T = Record<string,  unknown>>(key: string, value: T): void {
  setInLocalStorage(key, JSON.stringify(value));
}
