import {getFromLocalStorage, setInLocalStorage} from '@/localstorage';
import {AbstractMercureEvent, MercureEvents} from '@/mercure/MercureEvents';
import {isDevelopment} from '@/utils';
import mitt, {Emitter} from 'mitt';
import moment from 'moment-timezone';

export class MercureEventHandler {
  private _emitter?: Emitter<MercureEvents>;
  private _hub?: EventSource;

  private lastEventIdStorageKey = 'nl_mercure_last_event_id';
  private connected = false;
  private retryCounter = 0;
  private restartTimeout?: number;

  private startTimeout?: number;
  private expiry!: moment.Moment;
  private expiryTimeout?: number;

  constructor() {
    if (!EventSource) {
      console.error('Event source not supported by browser');
      return;
    }

    window.addEventListener('mercure-expiry-update',
        (e: Event) => this.setExpiry(moment((e as CustomEvent).detail.expiry)));

    this.setExpiry(moment(window.MERCURE_JWT_EXPIRY));
  }

  public registerHandler<Topic extends keyof MercureEvents>(topic: Topic, handler: (event: MercureEvents[Topic]) => void): void {
    this.emitter.on(topic, handler);

    // Postpone start to ensure other components have time to load correctly
    if (!this.connected && !this.startTimeout) {
      this.delayedStart();
    }

    if (topic === 'mercure-connection-status') {
      // Directly send current state when mercure connection handler is registered
      (handler as (event: MercureEvents['mercure-connection-status']) => MercureEvents['mercure-connection-status'])({
        timestamp: moment(),
        payload: this.startTimeout !== undefined ? 'starting' : (this.connected ? 'connected' : 'disconnected'),
      });
    }
  }

  public unregisterHandler<Topic extends keyof MercureEvents>(topic: Topic, handler: (event: MercureEvents[Topic]) => void): void {
    this.emitter.off(topic, handler);
  }

  public close(): void {
    if (!this._hub) {
      return;
    }

    this._hub.close();
    this._hub = undefined;
    this.setConnectedState(false);
  }

  public restart(timeout?: number): void {
    this.close();
    this.emitter.emit('mercure-connection-status', {
      timestamp: moment(),
      payload: 'reconnecting',
    });
    this.delayedStart(timeout);
  }

  private delayedStart(timeout = 5000): void {
    this.startTimeout = setTimeout(() => {
      try {
        this.start();
      } finally {
        delete this.startTimeout;
      }
    }, timeout);
  }

  private start(): void {
    if (this._hub || this.restartTimeout) {
      return;
    }

    // If expired, no longer retry
    if (this.expiry && this.expiry.clone().subtract(1, 'minute').isSameOrBefore(moment())) {
      console.info('Mercure token almost expired, not connecting.', this.expiry.toISOString());
      return;
    }

    // Determine the url
    const hubUrl = new URL(window.MERCURE_PUBLIC_URL);

    // Set the last event id
    const lastEventId = getFromLocalStorage(this.lastEventIdStorageKey);
    if (lastEventId) {
      hubUrl.searchParams.append('lastEventID', lastEventId);
    }

    // Subscribe to all topics, the token will filter accordingly
    hubUrl.searchParams.append('topic', '*');

    // Create the connection
    this._hub = new EventSource(hubUrl.toString(), {
      withCredentials: true,
    });

    // Register the handlers
    this._hub.onopen = () => {
      console.info('Mercure connection established');

      // Reset retry counter
      this.retryCounter = 0;
      this.setConnectedState(true);
    };
    this._hub.onmessage = (event) => {
      if (isDevelopment()) {
        console.info('Mercure event received', event);
      }

      // Store the last event id in the local storage
      setInLocalStorage(this.lastEventIdStorageKey, event.lastEventId);

      const eventData: AbstractMercureEvent<never> = JSON.parse(event.data);
      this.emitter.emit(eventData.event, {
        timestamp: moment(eventData.timestamp),
        payload: eventData.data,
      });
    };
    this._hub.onerror = (e) => {
      this.close();
      this.retryCounter++;

      if (this.retryCounter > 10) {
        console.error('Mercure connection error, giving up!');
        return;
      }

      console.info('Mercure error, restarting...', e);
      this.emitter.emit('mercure-connection-status', {
        timestamp: moment(),
        payload: 'reconnecting',
      });
      this.restartTimeout = setTimeout(() => {
        this.restartTimeout = undefined;
        this.start();
      }, 5000);
    };
  }

  private setConnectedState(connected: boolean): void {
    this.connected = connected;
    this.emitter.emit('mercure-connection-status', {
      timestamp: moment(),
      payload: connected ? 'connected' : 'disconnected',
    });
  }

  private setExpiry(expiry: moment.Moment): void {
    console.log('Mercure expiry updated to', expiry.toISOString());
    this.expiry = expiry;

    if (this.expiryTimeout) {
      clearTimeout(this.expiryTimeout);
      delete this.expiryTimeout;
    }

    this.expiryTimeout = setTimeout(() => this.expiryRestart(), this.expiryTimeoutTime());
  }

  private expiryRestart(): void {
    if (this.connected) {
      // Only try to restart when connected
      console.log('Restart due to near expiry of connection token', this.expiry.toISOString());
      this.close();
      this.emitter.emit('mercure-connection-status', {
        timestamp: moment(),
        payload: 'reconnecting',
      });
      this.start();
    }

    // Reset the timeout
    delete this.expiryTimeout;
    const timeout = this.expiryTimeoutTime();
    if (timeout > 1000) {
      this.expiryTimeout = setTimeout(() => this.expiryRestart(), timeout);
    }
  }

  private expiryTimeoutTime(): number {
    return Math.max(this.expiry.clone().subtract(30, 'seconds').diff(moment(), 'milliseconds'), 1000);
  }

  private get emitter(): Emitter<MercureEvents> {
    if (!this._emitter) {
      this._emitter = mitt();

      if (isDevelopment()) {
        // Register console logger for development environment
        this._emitter.on('*', (event, payload) => console.info(event, payload));
      }
    }

    return this._emitter;
  }
}
