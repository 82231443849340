import {DatePeriod} from '@/types/DatePeriod';
import {IdMapped} from '@/types/Generic';
import {Time} from '@/types/TimeTypes';
import moment from 'moment-timezone';
import Vue from 'vue';

export const archiveTypes: string[] = [
  'application/octet-stream',
  'application/zip',
  'application/x-zip-compressed',
  'application/x-rar-compressed',
];

export const dateTimeDateFormat = 'YYYY-MM-DD';
export const dateTimeTimeFormat = 'HH:mm';
export const dateTimeDateWithTimeFormat: string = dateTimeDateFormat + ' ' + dateTimeTimeFormat;
export const dateTimeLocalFormat = 'YYYY-MM-DDTHH:mm';

export function fileIcon(mimeType: string): string | string[] {
  let icon = 'file';

  if (mimeType.startsWith('image/')) {
    icon = 'file-image';
  } else if (mimeType.startsWith('text/')) {
    icon = 'file-alt';
  } else if (mimeType === 'application/pdf') {
    icon = 'file-pdf';
  } else if (archiveTypes.includes(mimeType)) {
    icon = 'file-archive';
  }

  return ['far', icon];
}

export interface ResolveActiveObject {
  start_date: string | null;
  end_date: string | null;
  is_active?: boolean;
}

export interface ResolveActiveTimeObject {
  start_date_time: string | null;
  end_date_time: string | null;
  is_active?: boolean;
}

export function resolveActive(start: string | null, end: string | null, now: moment.Moment): boolean {
  if (start && Vue.prototype.$moment(start).isAfter(now)) {
    return false;
  } else if (end && Vue.prototype.$moment(end).isSameOrBefore(now)) {
    return false;
  } else {
    return true;
  }
}

export function isActiveOverlap(
  start1: moment.Moment | null, end1: moment.Moment | null, start2: moment.Moment | null, end2: moment.Moment | null): boolean {
  // Convert start to UTC times for comparison
  const utcStart1 = start1 ? start1.unix() : 0;
  const utcStart2 = start2 ? start2.unix() : 0;
  const utcEnd1 = end1 ? end1.unix() : Infinity;
  const utcEnd2 = end2 ? end2.unix() : Infinity;

  return utcEnd1 >= utcStart2 && utcEnd2 >= utcStart1;
}

export function resolveActiveForObject(object: ResolveActiveObject, now: moment.Moment): void {
  object.is_active = resolveActive(object.start_date, object.end_date, now);
}

export function resolveActiveForObjectWithTime(object: ResolveActiveTimeObject, now: moment.Moment): void {
  object.is_active = resolveActive(object.start_date_time, object.end_date_time, now);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadActiveFlag(data: any[]): any[] {
  data.forEach((o) => {
    if (typeof (o.is_active) === 'undefined') {
      Vue.set(o, 'is_active', false);
    }
  });

  return data;
}

export function roundMoment(
  value: moment.Moment, minutes = 15, mode: 'floor' | 'ceil' | 'round' = 'round'): moment.Moment {
  let val = value.minutes() / minutes;
  switch (mode) {
    case 'floor':
      val = Math.floor(val);
      break;
    case 'ceil':
      val = Math.ceil(val);
      break;
    case 'round':
      val = Math.round(val);
      break;
  }

  return value.minutes(val * minutes).second(0);
}

/**
 * "Smart" diffs a value change. If the time between the two values hasn't changed, it will use
 * a day based diff to prevent timezone changes from happening
 */
export function applyMomentSmartDiff(newValue: string, oldValue: string, applyTo: moment.Moment): moment.Moment {
  const newValueMoment = Vue.prototype.$moment(newValue);
  const oldValueMoment = Vue.prototype.$moment(oldValue);

  if (newValueMoment.hours() === oldValueMoment.hours() &&
    newValueMoment.minutes() === oldValueMoment.minutes() &&
    newValueMoment.seconds() === oldValueMoment.seconds()) {
    // Use day based difference as time hasn't changed
    const monthDiff = newValueMoment.diff(oldValueMoment, 'months', true);
    if (Number.isInteger(monthDiff)) {
      // When the change has been a full month, apply the same to the other value
      return applyTo.add(monthDiff, 'months');
    } else {
      return applyTo.add(newValueMoment.diff(oldValueMoment, 'days'), 'days');
    }
  } else {
    return applyTo.add(newValueMoment.diff(oldValueMoment));
  }
}

export function timeToString(value?: Time | null): string {
  if (!value || (!value.hour && !value.minute)) {
    return '-';
  }

  return String(value.hour).padStart(2, '0') + ':' + String(value.minute).padStart(2, '0');
}

export function momentToTime(value?: moment.Moment): Time {
  return {hour: value?.hour() ?? 0, minute: value?.minute() ?? 0};
}

export function datePeriodToString(value?: DatePeriod): string {
  if (!value) {
    return '-';
  }

  const items: string[] = [];

  if (value.years) {
    items.push(Vue.prototype.$translator.trans('general.date.years-count', {years: value.years}));
  }

  if (value.months) {
    items.push(Vue.prototype.$translator.trans('general.date.months-count', {months: value.months}));
  }

  if (value.days) {
    items.push(Vue.prototype.$translator.trans('general.date.days-count', {days: value.days}));
  }

  const andSeparator = ' ' + Vue.prototype.$translator.trans('general.and').toLocaleLowerCase() + ' ';
  switch (items.length) {
    case 1:
      return items[0];
    case 2:
      return items.join(andSeparator);
    case 3:
      return [[items[0], items[1]].join(', '), items[2]].join(andSeparator);
    default:
      return '-';
  }
}

export function noop(): void {
  // no-op
}

export function escapeRegExp(value: string): string {
  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
  return value.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function mapById<T extends { id: number }>(value: Array<T>): IdMapped<T> {
  const result: IdMapped<T> = {};
  value.forEach((v) => {
    result[v.id] = v;
  });

  return result;
}

export function isProduction(): boolean {
  return process.env.NODE_ENV === 'production';
}

export function isDevelopment(): boolean {
  return !isProduction();
}

export function notNull<TValue>(value: TValue | null): value is TValue {
  return value !== null;
}
